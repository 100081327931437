








import { mapState } from 'vuex';

import CopyStakeSettingsForm from '@/views/CopyStake/CopyStakeStreamer/CopyStakeSettingsForm.vue';
import CopyStakeDisclaimer from '@/views/CopyStake/CopyStakeStreamer/CopyStakeDisclaimer.vue';
import CopyStakeStreamersTable from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamersTable.vue';

export default {
  name: 'CopyStakeStreamerView',
  components: {
    CopyStakeStreamersTable,
    CopyStakeSettingsForm,
    CopyStakeDisclaimer
  },
  computed: {
    ...mapState('FeatureFlags', ['features', 'loading']),
    ...mapState('app', ['isSuperAdmin']),

    isEnabledCopyStake(): boolean {
      return (
        this.features.find((feature) => feature.name === 'live_streamer')
          ?.enabled === true
      );
    }
  },
  watch: {
    loading: {
      handler(value: boolean): void {
        if (value || this.isEnabledCopyStake || this.isSuperAdmin) return;

        this.$router.push('/');
      },
      immediate: true
    }
  }
};
